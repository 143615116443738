.c-info-cards {
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  &__subject {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
    &__color {
      @include square-dimensions(2rem);
      border-radius: 50%;
      margin-right: 1rem;
    }
    span {
      font-family: 'Roboto Slab', serif;
      font-size: 2.5rem;
      font-weight: 600;

      @media screen and (max-width: 900px) {
        font-size: 2rem;
      }

      small {
        font-family: 'Source Sans Pro', serif;
        font-weight: 400;
        font-size: 2.2rem;

        @media screen and (max-width: 900px) {
          font-size: 1.8rem;
        }
      }
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
  }
  &__single {
    background-color: $clr-tertiary;
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 1.75rem 13rem 1.75rem 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
    }

    &--small {

      .c-info-cards__single__profile {
        img {
          width: 2.5rem;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      min-width: 45%;
      width: 45%;

      @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        margin-bottom: 1rem;
      }

      span {
        font-family: 'Roboto Slab', serif;
        font-size: 2rem;
        font-weight: 600;
      }
      small {
        font-family: 'Source Sans Pro', serif;
        font-size: 1.5rem;
      }
    }
    &__profile {
      display: flex;
      align-items: center;
      margin-left: 5rem;

      @media screen and (max-width: 768px) {
        align-items: center;
        margin-left: 0;
        margin-bottom: 1rem;
      }

      img {
        width: 3.5rem;
        margin-right: 1rem;
      }
      div {
        display: flex;
        flex-direction: column;

        span {
          font-family: 'Roboto Slab', serif;
          font-size: 1.8rem;
          font-weight: 600;
        }
        small {
          font-family: 'Source Sans Pro', serif;
          font-size: 1.7rem;
        }
      }
    }
    .btn {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 1.75rem;
      margin: auto;
      display: flex;
      width: 10rem;

      @media screen and (max-width: 768px) {
        position: relative;
        margin: initial;
        right: initial;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }
}
