.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  opacity: 0;
  pointer-events: none;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
  }

  &__content {
    background-color: $clr-secondary;
    position: relative;
    z-index: 5;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    @include square-dimensions(5rem);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.js-modalActive {
  opacity: 1;
  pointer-events: all;
}
