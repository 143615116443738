.c-heading {
  display: flex;
  align-items: center;
  padding: 3rem 0 2rem;
  border-bottom: 1px solid $clr-tertiary;
  margin-bottom: 3rem;
  flex-direction: column;

  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }

  &--text {
    align-items: flex-start;
  }

  &--split {
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    margin: 0;

    @media screen and (max-width: 1023px) {
      font-size: 2.5rem;
    }
  }
  &__cta {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1023px) {
      margin-top: 1rem;
    }
    @media screen and (max-width: 500px) {
      flex-direction: column;
      width: 100%;
    }

    a, button {
      margin-left: 1rem;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
        margin-right: 1rem;
      }
      @media screen and (max-width: 500px) {
        margin: .5rem 0;
        width: 100%;
      }
    }
  }
}
