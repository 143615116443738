.c-floating-card {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;



  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 45, 88, .5);
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    @include square-dimensions(5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $clr-primary;
    border: none;

    img {
      width: 2.5rem;
      transform: rotate(45deg);
    }
  }
  &__profile {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    img {
      width: 3.4rem;
      margin-right: 1rem;
    }
    div {
      display: flex;
      flex-direction: column;

      span {
        font-size: 1.7rem;
        font-family: 'Roboto Slab', serif;
        font-weight: 600;
      }
      small {
        font-size: 1.4rem;
      }
    }
  }
  &__single {
    background-color: $clr-secondary;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 55rem;
    min-width: 55rem;
    height: 100vh;
    min-height: 100vh;
    overflow-x: auto;
    display: none;

    &.js-floating-active{
      display: block;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
      min-width: 100%;
    }

    &__info {
      padding: 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid $clr-tertiary;

      &:last-of-type {
        border-bottom: none;
      }

      &:nth-of-type(1) {
        padding: 5rem 3rem 3rem;
      }

      h2 {
        font-size: 2.8rem;
        max-width: calc(100% - 5rem);
      }
      h3 {
        font-size: 2.3rem;
      }
      p {
        font-size: 1.8rem;
        margin: 0 0 .25rem;
        word-break: break-word;
      }
      br {

      }
      a {
        margin-top: 2rem;

        @media screen and (max-width: 550px) {
          width: 100%;
        }
      }
    }

    .c-info-cards__subject {
      margin: 0;

      span {
        font-family: 'Source Sans Pro', serif;
        font-weight: 600;
        font-size: 1.8rem;
      }
    }
  }
}
