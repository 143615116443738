.c-footer {
  padding: 2.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-top: 1px solid $clr-tertiary;
  background-color: $clr-tertiary;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  span {
    text-align: center;
    font-size: 1.7rem;

    strong {
      font-family: 'Roboto Slab', serif;
    }
  }
  a {
    font-size: 1.8rem;
    margin-left: 1rem;

    @media screen and (max-width: 650px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
