.c-map {
  width: 100%;
  height: calc(100vh - 16.5rem);
  position: relative;
  background-color: lightblue;

  .btn {
    position: absolute;
    top: 3rem;
    right: 3rem;
    z-index: 5;

    img {
      transform: rotate(45deg);
    }

  }

  .mapboxgl-marker{
    cursor: pointer;
  }
}
