.c-footing {
  display: flex;
  align-items: flex-start;
  padding: 3rem 0 3rem;
  margin: 3rem 0;
  border-top: 1px solid $clr-tertiary;

  a, button {
    margin-right: 1rem;
  }
}
