.c-status-card {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }

  &__single {
    width: 25%;
    margin-right: 2.5rem;
    padding: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: $clr-secondary;
    border-radius: 1rem;
    border-width: 7px;
    border: solid;
    border-color: $clr-primary;
    position: relative;

    &:after {
      opacity: 0 !important;
    }

    &:hover {
      .c-status-card__single__check {
        &:after {
          opacity: .7 !important;
        }
      }
    }

    @media screen and (max-width: 1023px) {
      width: calc(50% - 2rem);
      margin-right: 0;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
      padding: 2rem;
    }

    &:last-of-type {
      margin-right: 0;

      @media screen and (max-width: 1023px) {
        margin-right: initial;
      }
    }
    &:first-of-type {
      @media screen and (max-width: 550px) {
        margin-bottom: 0;
      }
    }
    &:nth-of-type(odd) {
      @media screen and (max-width: 1023px) {
        margin-right: 2rem;
      }
      @media screen and (max-width: 550px) {
        margin-right: 0;
      }
    }
    &:nth-of-type(1) {
      @media screen and (max-width: 550px) {
        order: 4;
      }
    }
    &:nth-of-type(2) {
      @media screen and (max-width: 550px) {
        order: 3;
      }
    }
    &:nth-of-type(3) {
      @media screen and (max-width: 550px) {
        order: 2;
      }
    }
    &:nth-of-type(4) {
      @media screen and (max-width: 550px) {
        order: 1;
      }
    }
    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &--1 {
      border-color: red;
      &:after {
        background-color: red;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        border-radius: 1rem;
        opacity: .3;
      }

      h3 {
        color: red;
      }
      .c-status-card__single__check {
        border: 3px solid red;
        background-color: red;
      }
    }
    &--2 {
      border-color: mediumpurple;

      &:after {
        background-color: mediumpurple;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        border-radius: 1rem;
        opacity: .3;
      }

      h3 {
        color: mediumpurple;
      }
      .c-status-card__single__check {
        border: 3px solid mediumpurple;
        background-color: mediumpurple;
      }
    }
    &--3 {
      border-color: forestgreen;

      &:after {
        background-color: forestgreen;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        border-radius: 1rem;
        opacity: .3;
      }

      h3 {
        color: forestgreen;
      }
      .c-status-card__single__check {
        border: 3px solid forestgreen;
        background-color: forestgreen;
      }
    }
    &--4 {
      border-color: goldenrod;

      &:after {
        background-color: goldenrod;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        border-radius: 1rem;
        opacity: .3;
      }

      h3 {
        color: goldenrod;
      }
      .c-status-card__single__check {
        border: 3px solid goldenrod;
        background-color: goldenrod;
      }
    }

    h3 {
      font-size: 2.3rem;
      text-align: center;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      position: relative;
      z-index: 1;

      @media screen and (max-width: 550px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.7rem;
      z-index: 1;
      position: relative;
    }

    &__check {
      @include square-dimensions(7rem);
      border-radius: 50%;
      border-width: 7px;
      border: solid;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: white;
        border-radius: 50%;

      }

      @media screen and (max-width: 550px) {
        @include square-dimensions(4rem);
        border-radius: 50%;
        border-width: 3px;

        svg {
          transform: scale(0.7);
        }
      }
    }
  }

  .js-isActive {
    opacity: 1;

    &:after {
      opacity: .3 !important;
    }

    .c-status-card__single__check {
      &:after {
        display: none;
      }
    }
  }
}
