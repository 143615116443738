.c-auth {
  width: 100%;
  height: 100vh;
  display: flex;
  &__interaction {
    width: 55rem;
    max-width: 55rem;
    min-width: 55rem;
    padding: 5rem 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1023px) {
      padding: 5rem;
      width: 45rem;
      max-width: 45rem;
      min-width: 45rem;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
  &__logo {
    max-width: 15rem;
    margin-bottom: 5rem;
  }
  &__form {
    h1 {
      font-size: 3.25rem;
      margin-bottom: 3rem;
    }
  }
  &__media {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
