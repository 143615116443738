/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Colors names
$blue-primary: #1D2D58;
$gray-primary: #F3F4F6;
$orange-primary: #E67A01;
$white-primary: #ffffff;

// Main colors
$clr-primary: $blue-primary;
$clr-secondary: $white-primary;
$clr-tertiary: $gray-primary;
$clr-quaternary: $orange-primary;

/*------------------------------------*\
  #ANIMATION
\*------------------------------------*/

// Transtions
$transition1: 0.28s all;
$transition2: 0.38s all;
$transition3: 0.55s all;

/*------------------------------------*\
  #RESPONSIVE
\*------------------------------------*/

// Device widths
$mobile-width: 520px;
$tablet-width: 768px;
$between-tablet: 900px;
$desktop-width: 1024px;
