.c-stores__header{
  display: none !important;
}

.c-stores{
  a {
    &:hover{
      cursor: pointer;
    }
  }
}

.c-header {
  background-color: $clr-tertiary;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__profile {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      margin-right: 2rem;
    }
    @media screen and (max-width: 500px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    img {
      width: 3.5rem;
      margin-right: 1.5rem;
    }
    .select-btn {
      display: flex;
      align-items: flex-end;
      background-color: transparent;
      padding: 0;
      margin: 0;
      border: none;
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      small {
        font-size: 1.4rem;
        margin: 0;
      }
      span {
        font-family: 'Roboto Slab', serif;
        font-weight: 600;
        font-size: 1.9rem;
      }
      img {
        width: 1.5rem;
        margin: 0 0 .6rem .5rem;
      }
    }
  }
  &__logo {
    display: flex;
    width: 90px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    @media screen and (max-width: 768px) {
      display: none;
    }

    img {
      width: 9rem;
    }
  }
  .btn {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

.c-stores {
  background-color: darken($clr-tertiary, 3%);;
  padding: 0;
  opacity: 0;
  height: 0;
  pointer-events: none;
  overflow: hidden;
  transition: .3s all;

  .o-container {
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid darken($clr-tertiary, 10%);
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    small {
      font-size: 1.4rem;
      margin: 0;
    }
    span {
      font-family: 'Roboto Slab', serif;
      font-weight: 600;
      font-size: 2.3rem;
    }
  }
  &__single {
    text-decoration: none;
    margin-bottom: 1rem;

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
    span {
      font-size: 1.8rem;
      font-family: 'Roboto Slab', serif;
    }
  }
  .isActive {
    color: $clr-quaternary;
    text-decoration: underline;
    font-weight: 600;
  }
}

.storesActive {
  .c-header__profile {
    button {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .c-stores {
    height: auto;
    opacity: 1;
    pointer-events: all;
    overflow: auto;
    padding: 3rem 0;
    border-top: 1px solid darken($clr-tertiary, 10%);
    border-bottom: 1px solid darken($clr-tertiary, 10%);
  }
}
