/*------------------------------------*\
  #STANDARD BUTTON
\*------------------------------------*/

.btn {
  padding: 1rem 1.5rem;
  min-height: 4rem;
  height: 4rem;
  border: none;
  border-radius: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    text-decoration: none;

    small {
      font-size: 1.3rem;
      font-family: 'Source Sans Pro', serif;
      font-weight: 500;
    }
  }
  &--blue {
    background-color: $clr-primary;

    span {
      color: $clr-secondary;
    }

    &:hover {
      background-color: darken($clr-primary, 10%);
    }
  }
  &--orange {
    background-color: $clr-quaternary;

    span {
      color: $clr-secondary;
    }
    &:hover {
      background-color: $clr-primary;
    }
  }
  &--text {
    min-height: auto;
    height: auto;
    text-decoration: underline;
    font-size: 1.7rem;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }

  &--outline-orange {
    border: 2px solid $clr-quaternary;
    text-decoration: none;
    background-color: transparent;

    span {
      color: $clr-primary;
    }
    &:hover {
      background-color: $clr-primary;
      border: 2px solid $clr-primary;
      span {
        color: $clr-secondary;
      }
    }
  }
  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .75rem 1.5rem;

    img {
      max-width: 1.5rem;
      margin: 0 .25rem;
    }
    span {
      margin: 0 .25rem;
    }
  }
}
