/*------------------------------------*\
  #Form
\*------------------------------------*/

.c-form {
  display: flex;
  flex-direction: column;

  &__split {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__half {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &:nth-child(1) {
      margin-right: 2rem;

      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }
  }

  &__break {
    width: 100%;
    height: 1px;
    display: flex;
    background-color: $clr-tertiary;
    margin: 3rem 0;
  }

  &__row {
    margin-bottom: 1.5rem;

    @media screen and (max-width: 550px) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &--half {
      display: flex;

      @media screen and (max-width: 550px) {
        flex-direction: column;
      }

      .c-form__group {
        width: 50%;

        @media screen and (max-width: 550px) {
          width: 100%;
        }

        &:first-of-type {
          margin-right: 1.5rem;
        }
      }
    }
    &--button-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 0;
      .c-form__group {
        width: 100%;

        @media screen and (max-width: 550px) {
          margin-bottom: 0;
        }
      }

      a,button {
        margin-left: 1.5rem;
        white-space: nowrap;
      }
    }
    &--address {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 550px) {
        flex-direction: column;
      }

      .c-form__group:nth-of-type(1) {
        width: 60%;

        @media screen and (max-width: 550px) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
      .c-form__group:nth-of-type(2), .c-form__group:nth-of-type(3) {
        width: 20%;
        margin-left: 1.5rem;

        @media screen and (max-width: 550px) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 1.5rem;
        }
      }
    }
    &--name {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 550px) {
        flex-direction: column;
      }

      .c-form__group:nth-of-type(1), .c-form__group:nth-of-type(3) {
        width: 40%;

        @media screen and (max-width: 550px) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
      .c-form__group:nth-of-type(1), .c-form__group:nth-of-type(2) {
        padding-right: 1.5rem;
      }
      .c-form__group:nth-of-type(2) {
        width: 20%;

        @media screen and (max-width: 550px) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  &__group {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 550px) {
      margin-bottom: 1.5rem;
    }

    label {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: .25rem;
    }

    input[type=text], input[type=email], input[type=password], input[type=number], select {
      height: 4rem;
      min-height: 4rem;
      padding: .25rem 1.25rem;
      border: 1px solid $clr-primary;
      box-shadow: none;
      border-radius: .5rem;
      font-size: 1.7rem;
    }

    input[type=checkbox] {
      height: auto;
      width: auto;
      appearance: checkbox;
      -webkit-appearance: checkbox;
      -ms-progress-appearance: checkbox;
      -moz-appearance: checkbox;
      cursor: pointer;
    }

    textarea {
      width: 100%;
      resize: vertical;
    }

    select {
      cursor: pointer;
    }

    &--checkbox {
    }

    &--button {
    }
  }
  &__select {
  }
}
