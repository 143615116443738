.s-dashboard {
  padding-bottom: 5rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 10rem;
  }
}

.s-add-company {
  .c-status-card {
    padding-bottom: 0;
  }
}

.s-onboarding {
  max-width: 192rem;
  width: 100%;
  margin: 0 auto;

  &__header {
    height: 6rem;
    min-height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 3rem;
    img {
      max-width: 7rem;
    }
  }
  &__slider {
    height: calc(100vh - 12rem);
    min-height: calc(100vh - 12rem);
    background-color: $clr-primary;
  }
  &__single {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: $clr-primary;
    overflow: hidden;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }

    &__text {
      width: 50%;
      height: 100%;
      padding: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: $clr-secondary;
      border-bottom-left-radius: 3rem;

      @media screen and (max-width: 1023px) {
        order: 2;
        height: 60%;
        width: 100%;
        border-bottom-right-radius: 3rem;
      }
      @media screen and (max-width: 650px) {
        height: 100%;
        padding: 6rem 3rem;
      }

      div {
        display: flex;

        button {
          margin-right: 1rem;
        }
      }

      small {
        font-family: 'Roboto Slab', serif;
        font-weight: bold;
        border: 1px solid $clr-primary;
        border-radius: .5rem;
        padding: .25rem .5rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
      }
      h1 {
      }
      p {
        font-size: 1.9rem;
      }
      button {

      }
    }
    &__media {
      width: 50%;
      height: 100%;
      background-color: $clr-tertiary;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4rem;
      border-bottom-right-radius: 3rem;

      @media screen and (max-width: 1023px) {
        order: 1;
        height: 40%;
        width: 100%;
        border-bottom-right-radius: 0;
      }
      @media screen and (max-width: 650px) {
        display: none;
      }

      img {
        max-height: 100%;
      }
    }
  }
  &__skip {
    height: 6rem;
    min-height: 6rem;
    background-color: $clr-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    max-width: 192rem;
    margin: 0 auto;

    a {
      color: $clr-secondary;
      font-family: 'Roboto Slab', serif;
      font-weight: 600;
    }
  }
}
